@import 'pallette';
@import 'utils';

// user page

.user-avatar {
  @include rounded-corners();
  width: 125px;
  height: 125px;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
}

.username {
  i {
    font-size: 14px;
    font-weight: normal;
    padding: 3px;
  }

  .action-lock-account:hover, .action-pgp:hover {
    background-color: gray;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
}

textarea[name="pgp-pub-key"] {
  resize: none;
}

#form-pgp .modal-body {
  padding-bottom: 0;
}

#modal-pgp {

  .modal-title {
    a {
      font-size: 14px;
      color: gray;
    }

    a:hover {
      color: #1A619E;
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -ms-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease;
    }
  }

  .setting-pgp {

    .tooltip-inner {
      max-width: none;
      white-space: nowrap;
    }

    table td:last-child {
      text-align: right;
    }

    pre {
      background: none;
      border: none;
      padding: 0;
      margin: 0;
      color: gray;
    }

    .row:last-child {
      padding-top: 5px;
    }

    .pgp-header {
      margin-bottom: 5px;
      position: relative;
      .dates {
        font-size: 11px;
        position: absolute;
        bottom: 0;
        right: 0;
        text-align: right;
      }

      h4 {
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
  }
}

.organization-avatar {
  cursor: pointer;
}

.edit-avatar {
  position: absolute;
  width: 100px;
  height: 15px;
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  text-align: center;
  font-size: 10px;
  bottom: 9px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

#form-avatar input[name="avatar-url"] {
  margin-bottom: 10px;
}

.user-header {
  @include size(100%, 150px);
  padding: 20px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 40px;

  .user-badge {
    width: 80%;
    position: relative;
  }
  .user-badge > h1 { float: left; }
  .pull-right { @include size(20%, 100%); }
  .user-roles li { display: inline; }

  .header-body {
    @include size(100%, 100%);
    position: relative;
    margin-top: 4px;
  }

  .user-badge > img {
    float: left;
    margin-right: 20px;
  }

  .user-info {
    @include fromBottomRight(0, 0);
    text-align: right;
  }

  .user-roles {
    @include fromTopRight(0, 0);
    list-style-type: none;
  }

  .organization-role img {
    margin-bottom: 4px;
  }
}

.panel-stars {
  .panel-footer > .pull-right { font-size: 16px; }
  .panel-stars .panel-footer > .pull-left { margin-top: 2px; }
  tr { height: 42px; }
  td > .pull-left { margin-right: 5px; }
  td > .pull-right > span { font-size: 12px; }
  td > .pull-right > i { font-size: 18px; }

  .panel-footer {
    height: 34px;
    padding: 5px 10px;
  }

  tr:hover {
    background-color: $mainBackground;
    cursor: pointer;
  }
}

// authors page

.table-authors {
  tbody > tr > td { vertical-align: middle; }
  tbody > tr.authors-footer > td:last-child {
    padding: 0;
    .btn {
      float: right;
      border: none;
    }
  }

  thead {
    background-color: $dark;
    color: #f6cf17;
    font-weight: bold;
    td:first-child { width: 40px; }
    td:not(:first-child) { cursor: pointer; }
    td:hover:not(:first-child) { background-color: #222; }
    .author-sort { background-color: #292929; }
  }
}

.notification-controls {
  margin-top: 20px;
}

.notification:hover {
  background-color: #f3f2f0;
  cursor: pointer;
}

.btn-mark-read {
  padding: 5px;
  position: absolute;
  width: 25px;
  height: 25px;
  top: 30%;
  right: 1%;
  @include rounded-corners();
}

.btn-mark-read:hover {
  background-color: #5cb85c;
  color: white;
  -webkit-transition: all .2s ease-out;
  -moz-transition: all .2s ease-out;
  -ms-transition: all .2s ease-out;
  -o-transition: all .2s ease-out;
  transition: all .2s ease-out;
}

.dismiss {
  padding: 5px;
  text-align: center;
}

.dismiss:hover {
  color: white;
  background-color: gray;
  -webkit-transition: all .2s ease-out;
  -moz-transition: all .2s ease-out;
  -ms-transition: all .2s ease-out;
  -o-transition: all .2s ease-out;
  transition: all .2s ease-out;
}

.user-cancel {
  cursor: pointer;
  padding: 1px;
  color: gray;
}

.user-cancel:hover {
  background-color: #ddd;
}

.invite {
  padding-left: 0;
  padding-right: 5px;
  padding-bottom: 5px;
  overflow: hidden;

  .invite-content {
    background-color: white;
    border: 1px solid $lighter;
    text-align: center;
    padding: 5px;

    .invite-message {
      position: absolute;
      width: 90%;
      top: 30%;
    }

    .invite-accepted {
      top: 20%;
    }

    .invite-loading {
      position: absolute;
      top: 30%;
      right: 35%;
    }
  }
}

.prompt {
  @include rounded-corners();
  z-index: 0;
  display: block;

  .popover-content { position: relative; }

  .btn-got-it {
    position: absolute;
    right: 15px;
    bottom: 5px;
  }
}

.popover-avatar {
  left: 105px;
  top: -11px;
}

.popover-pgp {
  top: -5px;
  left: 275px;
}
