@import 'pallette';
@import 'utils';

/* footer */

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  min-height: 60px;
  height: 60px;
  padding: 0 10px 0 10px;
  a { outline: none; }
  .col-md-4.col-spongie { padding: 0; }
  .info { display: table; }

  .col-md-4 {
    @include padding-horiz(5px, 5px);
    font-size: 12px;
    padding-top: 12px;
    vertical-align: middle;
    border-top: 1px solid $lighter;
  }

  .info > a, .info > .copyright {
    display: table-cell;
    padding-right: 10px;
    vertical-align: middle;
  }

  .commit {
    font-size: 10px;
    color: $mainBackground;
  }

  .commit:hover {
    color: $mainBackground;
  }
}

.footer > .navbar-default {
  background-color: $light;
  border: 0;
  border-top: 1px solid #A5A5A5;
}

.spongie-link {
  @include size(50px, 50px);
  display: block;
  margin: 0 auto;
}

.spongie {
  @include transition3(filter, 0.15s, ease-in-out);
  filter: grayscale(100%) contrast(30%);
  &:hover {
    filter: none;
  }
}

.copy {
  font-size: 11px;
  padding-right: 2px;
}

.copyright {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.social {
  display: table;
  .fa-twitter:hover { color: #00aced; }
  .fa-facebook-official:hover { color: #3b5998; }
  .fa-reddit:hover { color: #3399ff; }
}

.social > a {
  display: table-cell;
  padding-left: 10px;
  vertical-align: middle;
}

.social > .social-icon {
  color: #727272;
}

.social > a > .fa {
  @include transition3(color, 0.15s, ease-in-out);
}
