@import 'footer';
@import 'nav';
@import 'utils';
@import 'signUp';
@import 'email';
@import 'home';
@import 'user';
@import 'settings';

html {
  position: relative;
  min-height: 100%;
}

body {
  background-color: $mainBackground;
  margin-bottom: 60px;
  &.body-navbar-pad {
    padding-top: $sp_topbar_height;
  }
}

.top-level {
  margin-top: 30px;
}

.modal-body {
  width: 100%;
}

.panel {
  .panel-heading { background-color: white; }
  .panel-footer { background-color: white; }
}

.index-header {
  @include padding-vert(20px, 20px);
}

.index-header .alert {
  margin-bottom: 20px;
}

.logo {
  height: 40px;
}

.sponsor-logo {
  max-height: 35px;
  max-width: 100%;
}

.sponsor {
  @include padding-horiz(5px, 5px);
  @include rounded-corners();
  max-height: 60px;
  background-color: #ddd;
  border: 1px solid $light;
  padding-bottom: 5px;
  i {
    font-size: 10px;
    color: #fafafa;
  }
}

.sponsor-featured {
  margin-bottom: 20px;
}

form {
  @include margin-vert(10px, 10px);
}

.form-group {
  margin-bottom: 10px;
}

.tos {
  font-size: 11px;
  color: gray;
  margin-bottom: 5px;
}

.btn-more {
  width: 100%;
  background-color: #f5f5f5;
}

/* project content */

.project-list {
  .list-group-item:not(.project-list-footer) {
    padding: 15px;
    white-space: nowrap;
    overflow: hidden;
  }

  .list-group-item.project-hidden {
    background: repeating-linear-gradient(45deg, $lighter, $lighter 10px, #f5f5f5 10px, #f5f5f5 20px);
  }

  .list-group-item.project-list-footer {
    padding: 0;
    .btn { border: none; }
  }

  .stat {
    margin-left: 10px;
    a { color: gray; }
    a:hover { color: darken(gray, 10%); }
  }

  .project-list-header {
    width: 60%;
    a {
      margin-top: 3px;
      margin-right: 15px;
    }
  }

  .project-title {
    strong { font-size: 24px; }
    strong, i {
      display: table-cell;
      vertical-align: middle;
    }
  }

}

// category table

.select-sort {
  margin-bottom: 10px;
}

.setting {
  @extend .minor;
  @extend .form-group;
  border-bottom: 1px solid $light;
  padding: 20px;
  position: relative;
  form { margin: 0; }
  .danger { color: #B34D4D; }

  h4 {
    font-weight: bold;
    margin-top: 0;
    i {
      font-weight: normal;
      font-size: 14px;
    }
  }

  .setting-content {
    width: 25%;
    height: 100%;
    float: right;
  }

  .setting-description {
    width: 60%;
    height: 100%;
    float: left;
  }

  .icon-description {
    position: absolute;
    bottom: 20px;
    right: 20px;
    p { margin-bottom: 110px; }
  }

  #btn-rename, .btn-delete {
    width: 100%;
  }

}

.setting-no-border {
  border: none;
  margin-bottom: 0;
}

.category-reset {
  margin-top: -15px;
  margin-right: -4px;
}

.category-table {
  tr.selected:hover { background: #f6d830; }
  tr.selected > td { padding-top: 8px; }
  tr > td:first-child {
    text-align: center;
    padding-right: 0;
  }

  tr:hover {
    cursor: pointer;
    background-color: $mainBackground;
  }

  tr.selected {
    @include box-shadow5(inset, 0, 0, 5px, #e6b800);
    background: #f6d830;
    border: none;
  }
}

.cat-icon {
  width: 10px;
  text-align: center;
}

.container-error {
  margin-top: 10%;
  text-align: center;
  img {
    display: block;
    margin: 0 auto;
  }
}

.inline-form {
  display: inline-block;
}
