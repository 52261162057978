@import 'pallette';

.push-down {
  margin-top: 10px;
}

.fa {
  cursor: default;
}

.white > * {
  color: white;
}

.no-border > tbody > tr > td {
  border: none;
}

.date {
  color: gray;
  margin-right: 10px;
}

.date > a {
  color: gray;
}

.fa-check-circle {
  color: green;
}

.fa-times-circle {
  color: red;
}

.minor {
  color: gray;
}

.faint {
  color: #ccc;
}

.fa-external-link {
  font-size: 11px;
  margin-top: 2px;
}

a > .fa {
  cursor: pointer;
}

@mixin no-box-shadow() {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

@mixin box-shadow4($x, $y, $blur, $color) {
  -webkit-box-shadow: $x, $y, $color;
  -moz-box-shadow: $x, $y, $color;
  box-shadow: $x, $y, $color;
}

@mixin box-shadow5($x, $y, $blur, $color, $spread) {
  -webkit-box-shadow: $x, $y, $color, $spread;
  -moz-box-shadow: $x, $y, $color, $spread;
  box-shadow: $x, $y, $color, $spread;
}

@mixin transition2($attr, $time) {
  -webkit-transition: $attr $time;
  -moz-transition: $attr $time;
  -ms-transition: $attr $time;
  -o-transition: $attr $time;
  transition: $attr $time;
}

@mixin transition3($attr, $type, $time) {
  -webkit-transition: $attr, $type $time;
  -moz-transition: $attr, $type $time;
  -ms-transition: $attr, $type $time;
  -o-transition: $attr, $type $time;
  transition: $attr, $type $time;
}

@mixin transition6($attr, $type, $time, $attr1, $type1, $time1) {
  -webkit-transition: $attr, $type $time, $attr1, $type1, $time1;
  -moz-transition: $attr, $type $time, $attr1, $type1, $time1;
  -ms-transition: $attr, $type $time, $attr1, $type1, $time1;
  -o-transition: $attr, $type $time, $attr1, $type1, $time1;
  transition: $attr, $type $time, $attr1, $type1, $time1;
}

@mixin no-sides() {
  border-right: none;
  border-left: none;
}

@mixin padding-vert($top, $bottom) {
  padding-top: $top;
  padding-bottom: $bottom;
}

@mixin padding-horiz($left, $right) {
  padding-left: $left;
  padding-right: $right;
}

@mixin margin-vert($top, $bottom) {
  margin-top: $top;
  margin-bottom: $bottom;
}

@mixin margin-horiz($left, $right) {
  margin-left: $left;
  margin-right: $right;
}

@mixin basic-border() {
  border: 1px solid $lighter;
}

@mixin towardsBottomLeft($right, $top) {
  margin-right: $right;
  margin-top: $top;
}

@mixin towardsBottomRight($left, $top) {
  margin-left: $left;
  margin-top: $top;
}

@mixin towardsTopLeft($right, $bottom) {
  margin-right: $right;
  margin-bottom: $bottom;
}

@mixin towardsTopRight($left, $bottom) {
  margin-left: $left;
  margin-bottom: $bottom;
}

@mixin fromBottomLeft($left, $bottom) {
  position: absolute;
  left: $left;
  bottom: $bottom;
}

@mixin fromBottomRight($right, $bottom) {
  position: absolute;
  right: $right;
  bottom: $bottom;
}

@mixin fromTopRight($right, $top) {
  position: absolute;
  right: $right;
  top: $top;
}

@mixin fromTopLeft($left, $top) {
  position: absolute;
  left: $left;
  top: $top;
}

@mixin size($width, $height) {
  width: $width;
  height: $height;
}

@mixin rounded-corners() {
  border-radius: 4px;
}

@mixin circle() {
  border-radius: 50%;
}
